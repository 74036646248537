import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Developing, Deploying, Growing',
    paragraph: 'A workflow that works.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Deploying and analyzing
                  </div> */}
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Improve your profits with a batter digital strategy now
                  </div>
                <h3 className="mt-0 mb-12">
                  Website & Platforms
                  </h3>
                <p className="m-0">
                We guarantee your entire digital presence. With easy maintenance.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/pexels-pixabay-39284.jpg')}

                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                 1 week and you can start sharing 
                  </div>
                <h3 className="mt-0 mb-12">
                  Landing Pages 
                  </h3>
                <p className="m-0">
                 A landing page aligned with the core values of your company, with an easy UX and beautiful UI. We also make sure all marketing strategies are implemented.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                                  src={require('./../../assets/images/pexels-fauxels-3183170.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

         

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Modern Design
                  </div>
                {/* <h3 className="mt-0 mb-12">
                  Website developed, running, ready to be shared!
                  </h3>
                <p className="m-0">
                  Now that your company exists in the digital world, you can start making your profits out of it!
                  </p> */}
                <h3 className="mt-0 mb-12">
                 Not only functional but also beautiful.
                  </h3>
                <p className="m-0">
                  We have the most talented designers ready to start implementing your idea with the best UX practices. We also redesign your entire website.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/pexels-canva-studio-3194521.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;